<style scoped>
    .action-link {
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div>
            <div class="card card-default">
                <div class="card-header">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>
                            Tokens
                        </span>

                        <a class="action-link" tabindex="-1" @click="showCreateTokenForm">
                            Créer un token
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <!-- No Tokens Notice -->
                    <p class="mb-0" v-if="tokens.length === 0">
                        Aucun token.
                    </p>
                    <!-- Personal Access Tokens -->
                    <div class="table-responsive">
                        <table class="table " v-if="tokens.length > 0">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nom</th>
                                    <th>Domaines</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="token in tokens">
                                    <!-- Id -->
                                    <td style="vertical-align: middle;">
                                        {{ token.id }}
                                    </td>

                                    <!-- Name -->
                                    <td style="vertical-align: middle;">
                                        {{ token.name }}
                                    </td>

                                    <!-- Domains -->
                                    <td style="vertical-align: middle;">
                                        {{ token.domains }}
                                    </td>

                                    <!-- Edit Button -->
                                    <td class="text-right">
                                        <button type="button" class="btn btn-sm btn-outline-primary text-capitalize" @click="edittoken(token)">
                                            Modifier
                                        </button>
                                        <button type="button" class="btn btn-sm btn-outline-danger text-capitalize" @click="deletetoken(token)">
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Edit Token Modal -->
        <div class="modal fade" id="modal-edit-token" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Modifier un token
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <!-- Form Errors -->
                        <div class="alert alert-danger" v-if="form.errors.length > 0">
                            <p class="mb-0"><strong>Erreur</strong> </p>
                            <br>
                            <ul>
                                <li v-for="error in form.errors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>

                        <!-- Create Token Form -->
                        <form role="form" @submit.prevent="store">
                            <!-- Name -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nom</label>

                                <div class="col-md-6">
                                    <input id="edit-token-name" type="text" class="form-control" name="name" v-model="form.name">
                                </div>
                            </div>

                            <!-- Domains -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Domaines</label>

                                <div class="col-md-6">
                                    <input id="edit-token-domains" type="text" class="form-control" name="domains" v-model="form.domains">
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>

                        <button type="button" class="btn btn-primary" @click="update">
                            Modifier
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create Token Modal -->
        <div class="modal fade" id="modal-create-token" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Créer un token
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <!-- Form Errors -->
                        <div class="alert alert-danger" v-if="form.errors.length > 0">
                            <p class="mb-0"><strong>Erreur</strong></p>
                            <br>
                            <ul>
                                <li v-for="error in form.errors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>

                        <!-- Create Token Form -->
                        <form role="form" @submit.prevent="store">
                            <!-- Name -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Nom</label>

                                <div class="col-md-6">
                                    <input id="create-token-name" type="text" class="form-control" name="name" v-model="form.name">
                                </div>
                            </div>

                            <!-- Domains -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label">Domaines</label>

                                <div class="col-md-6">
                                    <input id="create-token-domains" type="text" class="form-control" name="domains" v-model="form.domains">
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>

                        <button type="button" class="btn btn-primary" @click="store">
                            Créer
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Access Token Modal -->
        <div class="modal fade" id="modal-access-token" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Token
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <p>
                            Voici votre nouveau token. C'est la seule fois que ce token sera affiché, ne le perdez pas!
                            Vous pouvez dès à présent l'utiliser pour vos requêtes API.
                        </p>

                        <textarea id="tokenarea" class="form-control" rows="10" readonly v-model="accessToken"></textarea>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="clearToken">Fermer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modal-delete-token" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Confirmation de suppression
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <p>
                            Voulez-vous réelement supprimer <span class="text-danger font-weight-bold">{{ selectedTokenName }}</span> ?
                        </p>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>

                        <button type="button" class="btn btn-danger" @click="revoke">
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            apiuseruuid: String
        },
        computed:
        {
            selectedTokenName: function() {
                return this.selectedToken && this.selectedToken.name ? this.selectedToken.name : ''
            }
        },
        /*
         * The component's data.
         */
        data() {
            return {
                accessToken: null,
                selectedToken: null,
                tokens: [],
                form: {
                    name: '',
                    errors: []
                },
            };
        },

        /**
         * Prepare the component (Vue 1.x).
         */
        ready() {
            this.prepareComponent();
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.prepareComponent();
        },

        methods: {
            /**
             * Prepare the component.
             */
            prepareComponent() {
                this.getTokens();
                $('#modal-create-token').on('shown.bs.modal', () => {
                    $('#create-token-name').focus();
                });
            },

            /**
             * Get all of the personal access tokens for the user.
             */
            getTokens() {
                axios.get('/apiusers/' + this.apiuseruuid + '/tokens')
                    .then(response => {
                        this.tokens = response.data.data
                        this.selectedToken = null
                    })
            },

            clearToken() {
                this.accessToken = null
            },

            /**
             * Show the form for creating new tokens.
             */
            showCreateTokenForm() {
                this.form.errors = []
                $('#modal-create-token').modal('show');
            },

            /**
             * Create a new personal access token.
             */
            store() {
                this.accessToken = null;

                this.form.errors = [];

                axios.post('/apiusers/' + this.apiuseruuid + '/tokens/store', this.form)
                    .then(response => {
                        this.form.name = '';
                        this.form.domains = '';
                        this.form.errors = [];

                        this.getTokens()

                        this.showAccessToken(response.data.data.accessToken);
                    })
                    .catch(error => {
                        if (typeof error.response.data === 'object') {
                            if (error.response.data.error) {
                                this.form.errors = [error.response.data.error]
                            } else if (error.response.data.exception && error.response.data.message) {
                                    this.form.errors = [error.response.data.message]
                            } else if (this.form.errors) {
                                this.form.errors = _.flatten(_.toArray(error.response.data.errors));
                            }
                        } else {
                            this.form.errors = ['Something went wrong. Please try again.'];
                        }
                    });
            },

            /**
             * Update a personal access token.
             */
            update() {
                axios.patch('/apiusers/' + this.apiuseruuid + '/tokens/update/' + this.selectedToken.id, this.form)
                    .then(response => {
                        this.form.name = '';
                        this.form.domains = '';
                        this.form.errors = [];

                        Vue.set(this.tokens, this.tokens.findIndex(token => token.id === response.data.data.token.id), response.data.data.token)
                        $('#modal-edit-token').modal('hide')
                        this.getTokens()
                    })
                    .catch(error => {
                        if (typeof error.response.data === 'object') {
                            if (error.response.data.error) {
                                this.form.errors = [error.response.data.error]
                            } else if (error.response.data.exception && error.response.data.message) {
                                    this.form.errors = [error.response.data.message]
                            } else if (this.form.errors) {
                                this.form.errors = _.flatten(_.toArray(error.response.data.errors));
                            }
                        } else {
                            this.form.errors = ['Something went wrong. Please try again.'];
                        }
                    });
            },

            /**
             * Show the given access token to the user.
             */
            showAccessToken(accessToken) {
                this.form.errors = []
                $('#modal-create-token').modal('hide');

                this.accessToken = accessToken;

                $('#modal-access-token').modal('show');
            },

            edittoken(token) {
                this.form.errors = []
                this.form.name = token.name
                this.form.domains = token.domains
                this.selectedToken = token
                $('#modal-edit-token').modal('show');
            },

            deletetoken(token) {
                this.selectedToken = token
                $('#modal-delete-token').modal('show');
            },

            /**
             * Revoke the given token.
             */
            revoke() {
                axios.delete('/apiusers/' + this.apiuseruuid + '/tokens/delete/' + this.selectedToken.id)
                        .then(response => {
                            $('#modal-delete-token').modal('hide')
                            this.getTokens()
                        })
                        .catch(error => {
                            if (typeof error.response.data === 'object') {
                                if (error.response.data.error) {
                                    this.form.errors = [error.response.data.error]
                                } else if (error.response.data.exception && error.response.data.message) {
                                        this.form.errors = [error.response.data.message]
                                } else if (this.form.errors) {
                                    this.form.errors = _.flatten(_.toArray(error.response.data.errors));
                                }
                            } else {
                                this.form.errors = ['Something went wrong. Please try again.'];
                            }
                        });
            }
        }
    }
</script>

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="align-middle text-nowrap">{{ $tc("Title", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Date", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $t("Deadline") }}</th>
        <th class="align-middle text-nowrap">{{ $t("Type") }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Author", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $t("Updated By") }}</th>
        <th class="align-middle text-nowrap" v-if="isSuperAdmin">{{ $tc("Id", 1) }}</th>
        <th class="align-middle text-nowrap text-right" colspan="2" v-if="canEdit || canDeactivate">{{ $tc("Action", 2) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items">
        <td class="align-middle">
          {{ item.title }}
        </td>
        <td class="align-middle">
          <small>{{ getFormattedDate(item.date) }}</small>
        </td>
        <td class="align-middle">
          <small>{{ getFormattedDate(item.to_date) }}</small>
        </td>
        <td class="align-middle">
          <small>{{ item.type && item.type.value ? item.type.value[$i18n.locale] : "—" }}</small>
        </td>
        <td class="align-middle">
          <small>{{ item.created_by && item.created_by.username ? item.created_by.username : "—" }}</small>
        </td>
        <td class="align-middle">
          <small>{{ item.last_updated_by && item.last_updated_by.username ? item.last_updated_by.username : "—" }}</small>
        </td>
        <td v-if="isSuperAdmin">
          {{ item.uuid }}
        </td>
        <td class="text-right align-middle" nowrap>
          <actions :canEdit="canEdit" :canDeactivate="canDeactivate" :item="item" :baseurl="baseurl" :hasPublishButton="true" :isPublished="!item.is_draft" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    baseurl: String,
    items: Array,
    isSuperAdmin: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
  },
  methods: {
    deleteItem(uuid) {
      this.$emit("deleteItem", uuid);
    },
    getEditUrl(uuid) {
      return this.baseurl + "/" + uuid + "/edit";
    },
    getFormattedDate(date) {
      if (date) {
        const d = new Date(date);
        return ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
      } else {
        return "—";
      }
    },
  },
};
</script>

import hotkeys from "hotkeys-js";

document.addEventListener("DOMContentLoaded", (event) => {
  if (document.querySelector("[type='submit'].visually-hidden")) {
    hotkeys.filter = function (event) {
      const tagName = (event.target || event.srcElement).tagName;
      hotkeys.setScope(/^(INPUT|TEXTAREA|SELECT)$/.test(tagName) ? "input" : "other");

      return true;
    };

    hotkeys("ctrl+s, command+s", (event, handler) => {
      event.preventDefault();
      document.querySelector("[type='submit'].visually-hidden").click();
    });
  }
});

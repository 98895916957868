<style scoped>
.searchbar {
  font-family: 'NeueMachina';
}
</style>

<template>
  <div>
      <Flash
        :type="flashtype"
        :message="message"
        :errors="errors"
        :time="flashtime"
        ref="flash"
      />

    <!-- Search box -->
    <div class="form-group" style="position:relative">
        <input type="text" class="searchbar form-control" :placeholder="$t('Search')" v-model="query" @input="search" aria-describedby="searchHelpBlock">
        <button v-if="query" type="button" @click="resetSearch" class="btn bg-transparent search-cancel">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </button>
        <searchhelper :endpoint="endpoint" />
    </div>

    <!-- Content -->
    <div class="table-responsive">
      <vue-loading type="spin" color="#444" :size="{ width: '25px', height: '25px' }" v-if="loading" class="m-0"></vue-loading>

      <artiststable :items="items" v-if="endpoint === 'artists' && !loading && (items && items.length)" @deleteItem="deleteItem" @publishItem="publishItem" @toggleIsNew="toggleIsNew" @toggleIsUpdated="toggleIsUpdated" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></artiststable>

      <artworkstable :items="items" v-if="endpoint === 'artworks' && !loading && (items && items.length)" @deleteItem="deleteItem" @publishItem="publishItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></artworkstable>

      <callstable :items="items" v-if="endpoint === 'calls' && !loading && (items && items.length)" @deleteItem="deleteItem" @publishItem="publishItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></callstable>

      <authorstable :items="items" v-if="endpoint === 'authors' && !loading && (items && items.length)" @deleteItem="deleteItem" @publishItem="publishItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></authorstable>

      <networkstable :items="items" v-if="endpoint === 'networks' && !loading && (items && items.length)" @deleteItem="deleteItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></networkstable>

      <newstable :items="items" v-if="endpoint === 'news' && !loading && (items && items.length)" @deleteItem="deleteItem" @publishItem="publishItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></newstable>

      <userstable :items="items" v-if="endpoint === 'users' && !loading && (items && items.length)" @deleteItem="deleteItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></userstable>

      <translationtagstable :items="items" v-if="endpoint === 'translations' && !loading && (items && items.length)" @updateItems="updateItems" @deleteItem="deleteItem" :isSuperAdmin="isSuperAdmin" :canEdit="canEdit" :canDeactivate="canDeactivate" :baseurl="baseurl"></translationtagstable>

      <span v-if="items && !items.length && !loading" class="text-muted">{{$tc('Item', 0)}}   ¯\_(￣ヘ￣)_/¯</span>
    </div>

    <!-- Pagination -->
    <div class="form-row" v-if="!loading && pagination && items && items.length">
      <div class="form-group col-md-3">
        <select id="limit" class="custom-select" v-model="limit" @change="getQuery">
          <option selected>{{$t('Items per page')}}</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <pagination v-if="pagination" :pagination="pagination" @paginate="getQuery()" :offset="4">
        </pagination>
      </div>
    </div>

  </div>
</template>

<script>
function isJSON(str) {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
}
import {debounce} from '../../helpers.js'
import Flash from './Flash/Flash'

  export default {
    components: {
      Flash
    },
    props: {
      baseurl: String,
      endpoint: String,
      roles: Array,
      permissions: Array,
      type: String
    },
    computed: {
      isSuperAdmin() {
        return this.roles.includes('super-admin')
      },
      canEdit() {
        return this.permissions.includes('edit ' + this.endpoint)
      },
      canDeactivate() {
        return this.permissions.includes('deactivate ' + this.endpoint)
      },
    },
    data() {
      return {
        query: '',
        items: [],
        pagination: {},
        errors: [],
        success: '',
        message: '',
        loading: true,
        limit: 10,
        flashtype: 'success',
        flashtime: 4000
      }
    },
    ready() {
      this.getQuery(this.query)
    },
    mounted() {
      this.query = localStorage.getItem(this.endpoint + '_query') || ''
      this.limit = localStorage.getItem(this.endpoint + '_limit') || 10
      this.pagination = (isJSON(localStorage.getItem(this.endpoint + '_pagination')) && JSON.parse(localStorage.getItem(this.endpoint + '_pagination'))) || {}
      this.getQuery(this.query)
      this.search = debounce(this.search, 300)
    },
    watch: {
      query(newQuery) {
        localStorage.setItem(this.endpoint + '_query', newQuery)
      },
      limit(newLimit) {
        localStorage.setItem(this.endpoint + '_limit', newLimit)
      },
      pagination(newPagination) {
        localStorage.setItem(this.endpoint + '_pagination', JSON.stringify(newPagination))
      },
    },
    methods: {
      search(clearnotices = false) {
        this.loading = true
        this.getQuery(true)
        this.clearFlash()
      },
      getQuery(resetPagination = false) {
        this.loading = true
        if (resetPagination && this.pagination) {
          this.pagination.current_page = 1
        }
        if (this.pagination) {
          this.pagination.current_page = isNaN(this.pagination.current_page) ? 1 : this.pagination.current_page
          this.pagination.limit = isNaN(this.pagination.limit) ? this.limit : this.pagination.limit
        }
        const type = this.type ? `&type=${this.type}` : ''
        axios.get(`/search/${this.endpoint}?q=${this.query}&page=${this.pagination.current_page}&limit=${this.limit}${type}`)
          .then(response => {
            this.items = response.data.data
            this.pagination = response.data.meta
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            let errors = []
            if (typeof error.response.data === 'object') {
              if (error.response.data.error) {
                errors = [error.response.data.error]
              } else if ((error.response.data.exception || error.response.status !== 200) && error.response.data.message) {
                errors = [error.response.data.message]
              } else if (this.errors) {
                errors = _.flatten(_.toArray(error.response.data.errors));
              }
            } else {
              errors = ['Something went wrong. Please try again.'];
            }
            this.flashError(errors)
          })
      },
      deleteItem(uuid) {
        if (confirm(this.$t('Are you sure you want to delete the item?'))) {
          axios.delete(this.getDestroyUrl(uuid))
            .then(response => {
              this.flashSuccess(response.data.message)
              this.getQuery()
            })
            .catch(error => {
              this.loading = false
              let errors = []
              if (typeof error.response.data === 'object') {
                if (error.response.data.error) {
                  errors = [error.response.data.error]
                } else if ((error.response.data.exception || error.response.status !== 200) && error.response.data.message) {
                  errors = [error.response.data.message]
                } else if (this.errors) {
                  errors = _.flatten(_.toArray(error.response.data.errors));
                }
              } else {
                errors = ['Something went wrong. Please try again.'];
              }
              this.flashError(errors)
            });
        }
      },
      updateItems() {
        axios.patch(this.baseurl, this.items)
          .then(response => {
            this.flashSuccess(response.data.message)
            this.getQuery()
          })
          .catch(error => {
            this.loading = false
            let errors = []
            if (typeof error.response.data === 'object') {
              if (error.response.data.error) {
                errors = [error.response.data.error]
              } else if ((error.response.data.exception || error.response.status !== 200) && error.response.data.message) {
                errors = [error.response.data.message]
              } else if (this.errors) {
                errors = _.flatten(_.toArray(error.response.data.errors));
              }
            } else {
              errors = ['Something went wrong. Please try again.'];
            }
            this.flashError(errors)
          })
      },
      publishItem(item) {
        axios.post(this.getPublishUrl(item.uuid))
          .then(response => {
            item.is_draft = !item.is_draft
            this.flashSuccess(response.data.message)
          })
          .catch(error => {
            this.loading = false
            let errors = []
            if (typeof error.response.data === 'object') {
              if (error.response.data.error) {
                errors = [error.response.data.error]
              } else if ((error.response.data.exception || error.response.status !== 200) && error.response.data.message) {
                errors = [error.response.data.message]
              } else if (this.errors) {
                errors = _.flatten(_.toArray(error.response.data.errors));
              }
            } else {
              errors = ['Something went wrong. Please try again.'];
            }
            this.flashError(errors)
          })
      },
      toggleIsNew(item) {
        axios.post(this.getToggleIsNewUrl(item.uuid))
          .then(response => {
            if (item.is_new === undefined) {
              this.$set(item, 'is_new', true)
            } else {
              item.is_new = !item.is_new
            }
            this.flashSuccess(response.data.message)
          })
          .catch(error => {
            this.loading = false
            let errors = []
            if (typeof error.response.data === 'object') {
              if (error.response.data.error) {
                errors = [error.response.data.error]
              } else if ((error.response.data.exception || error.response.status !== 200) && error.response.data.message) {
                errors = [error.response.data.message]
              } else if (message) {
                errors = _.flatten(_.toArray(error.response.data.errors));
              }
            } else {
              errors = ['Something went wrong. Please try again.'];
            }
            this.flashError(errors)
          })
      },
      toggleIsUpdated(item) {
        axios.post(this.getToggleIsUpdatedUrl(item.uuid))
          .then(response => {
            item.is_updated = !item.is_updated
            this.flashSuccess(response.data.message)
          })
          .catch(error => {
            this.loading = false
            let errors = []
            if (typeof error.response.data === 'object') {
              if (error.response.data.error) {
                errors = [error.response.data.error]
              } else if ((error.response.data.exception || error.response.status !== 200) && error.response.data.message) {
                errors = [error.response.data.message]
              } else if (message) {
                errors = _.flatten(_.toArray(error.response.data.errors));
              }
            } else {
              errors = ['Something went wrong. Please try again.'];
            }
            this.flashError(errors)
          })
      },
      getEditUrl(uuid) {
        return this.baseurl + '/' + uuid + '/edit'
      },
      getDestroyUrl(uuid) {
        return this.baseurl + '/' + uuid
      },
      getPublishUrl(uuid) {
        return this.baseurl + '/' + uuid + '/publish'
      },
      getToggleIsNewUrl(uuid) {
        return this.baseurl + '/' + uuid + '/toggleisnew'
      },
      getToggleIsUpdatedUrl(uuid) {
        return this.baseurl + '/' + uuid + '/toggleisupdated'
      },
      resetSearch() {
        this.query = ''
        if (this.pagination && this.pagination.current_page) {
          this.pagination.current_page = 1
        }
        this.search()
      },
      flashSuccess(message) {
        this.flashtype = "success"
        this.message = message
        this.$refs.flash.forceUpdate()
      },
      flashError(errors) {
        this.flashtype = "error"
        this.message = "error"
        this.errors = errors
        this.$refs.flash.forceUpdate()
      },
      clearFlash() {
        this.$refs.flash.clearFlashImmediate()
      }
    }
  }
</script>

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="align-middle text-nowrap">{{ $tc("Avatar", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Title", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $t("Type") }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Network", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("City", 2) }}</th>
        <th class="align-middle text-nowrap">{{ $t("Year") }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Artist", 2) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Author", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $t("Updated By") }}</th>
        <th class="align-middle text-nowrap" v-if="isSuperAdmin">{{ $tc("Id", 1) }}</th>
        <th class="align-middle text-nowrap text-right" colspan="2" v-if="canEdit || canDeactivate">{{ $tc("Action", 2) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items">
        <td class="align-middle">
          <img v-if="item.media !== undefined && item.media !== null && item.media.avatar && item.media.avatar.thumb" class="img-fluid rounded-circle" :src="item.media.avatar.thumb" width="35" alt="avatar" />
          <span v-else class="rounded-circle" style="background-color: #ddd; height: 35px; width: 35px; display: inline-block"></span>
        </td>
        <td class="align-middle">
          {{ item.title ? item.title : "—" }}
        </td>
        <td class="align-middle">
          <small>{{ item.is_public_space_artwork ? $tc(item.type, 1) : "—" }}</small>
        </td>
        <td class="align-middle">
          <small>
            {{ item.network && item.network.fullname ? item.network.fullname : "—" }}
          </small>
        </td>
        <td class="align-middle">
          <template v-if="item.cities">
            <small v-for="(city, index) in item.cities">
              <span v-if="index === 0">{{ city.name }}</span>
              <span v-else>, {{ city.name }}</span>
            </small>
          </template>
          <template v-else> — </template>
        </td>
        <td class="align-middle">
          <small>{{ item.year ? item.year : "—" }}</small>
        </td>
        <td class="align-middle">
          <small v-if="item.artists" v-for="(artist, index) in item.artists">
            <a v-if="index == 0" :href="'/artists/' + artist.uuid">{{ artist.fullname }}</a>
            <a v-else :href="'/artists/' + artist.uuid">, {{ artist.fullname }}</a>
          </small>
        </td>
        <td class="align-middle">
          <small>{{ item.created_by && item.created_by.username ? item.created_by.username : "—" }}</small>
        </td>
        <td class="align-middle">
          <small>{{ item.last_updated_by && item.last_updated_by.username ? item.last_updated_by.username : "—" }}</small>
        </td>
        <td v-if="isSuperAdmin">
          {{ item.uuid }}
        </td>
        <td class="text-right align-middle" nowrap>
          <actions :canEdit="canEdit" :canDeactivate="canDeactivate" :item="item" :baseurl="baseurl" :hasPublishButton="true" :isPublished="!item.is_draft" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    baseurl: String,
    items: Array,
    isSuperAdmin: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
  },
  methods: {
    deleteItem(uuid) {
      this.$emit("deleteItem", uuid);
    },
    getEditUrl(uuid) {
      return this.baseurl + "/" + uuid + "/edit";
    },
    getYearFromDate(date) {
      return date ? new Date(date).getFullYear() : "—";
    },
  },
};
</script>

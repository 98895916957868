import FileUploadWithPreview from '../modules/FileUploadWithPreview.js'

const updateModalContent = (event, modalSelector) => {
  const header = document.querySelector(`${modalSelector} .modal-title`)
  if (header) {
    header.innerHTML = event.detail.file['name'] ?? 'Media'
  }

  const meta = document.querySelector(`${modalSelector} .meta`)
  if (meta) {
    const fullurl = meta.querySelector('.fullurl')
    if (fullurl && event.detail.file['fullurl']) {
      fullurl.innerHTML = `<a href="${event.detail.file['fullurl']}" rel="noreferrer noopener" target="_blank">${event.detail.file['name']}</a>`
    } else {
      fullurl.innerHTML = ''
    }
    const humansize = meta.querySelector('.humansize')
    if (humansize && event.detail.file['humansize']) {
      humansize.innerHTML = `<span>${event.detail.file['humansize']}</span>`
    } else {
      humansize.innerHTML = ''
    }
  }

  const ids = [
    "title",
    "credits",
    "caption",
    "title-en",
    "credits-en",
    "caption-en",
  ]
  ids.forEach(id => {
    const selector = document.querySelector(`${modalSelector} .modal-body [name="view-${id}"]`)
    if (selector) {
      selector.value = event.detail.file[id] ?? ''
    }
  })
}

const getModalContent = (modalId) => {
  const ids = [
    "title",
    "credits",
    "caption",
    "title-en",
    "credits-en",
    "caption-en",
  ]
  const result = {}

  ids.forEach(id => {
    const selector = document.querySelector(`${modalId} .modal-body [name="view-${id}"]`)
    result[id] = selector ? selector.value : ''
  })

  return result
}

const setupFileUpload = (id = 'multiplefilesupload', metaEditorName = '[name="views_meta[]"]', modalSelector = '#views-modal', deleteListSelector = '[name="views_to_delete[]"]', multiple = true) => {
  const target = document.querySelector(`[data-upload-id=${id}]`)
  if (target) {
    const preloaded = target.getAttribute('data-upload-presetfiles') ? JSON.parse(target.getAttribute('data-upload-presetfiles')) : []
    const todeleteTarget = target.querySelector(deleteListSelector)
    const uploader = new FileUploadWithPreview(id, {
      presetFiles: preloaded && preloaded.map(item => item.thumbUrl),
      maxFileCount: 16
    })
    const deletedImages = []

    const clearButton = target.querySelector('.custom-file-container__image-clear')
    if (clearButton && multiple === false) {
      clearButton.addEventListener('click', () => {
        if (preloaded && preloaded[0]) {
          const toDelete = preloaded[0] ?? null
          if (toDelete) {
            preloaded.splice(0, 1)
            deletedImages.push({
              'id': toDelete.id,
              'name': toDelete.name.split(/(\\|\/)/g).pop()
            })
            todeleteTarget.value = JSON.stringify(deletedImages)
          }
        }
      })
    }
    window.addEventListener('fileUploadWithPreview:imageDeleted', e => {
      if (e.detail.uploadId === id) {
        if (preloaded) {
          const toDelete = preloaded[e.detail.index] ?? null
          if (toDelete) {
            preloaded.splice(e.detail.index, 1)
            deletedImages.push({
              'id': toDelete.id,
              'name': toDelete.name.split(/(\\|\/)/g).pop()
            })
            todeleteTarget.value = JSON.stringify(deletedImages)
          }
        }
      }
    })

    const modal = window.$(modalSelector)
    if (modal) {
      let items = []
      let item = null
      let index = -1

      const saveBtn = modal.find('[data-type="save"]')
      const viewsMetaInput = document.querySelector(metaEditorName)
      const viewsMetaData = []

      const saveModalEvent = e => {
        index = multiple === true ? uploader.cachedFileArray.findIndex(x => x.token === item.token) : 0
        item = {...item, ...getModalContent(modalSelector), index}
        viewsMetaData[index] = item
        if (preloaded) {
          preloaded[index] = item
        }
        if (uploader.cachedFileArray) {
          uploader.cachedFileArray[index] = item
        }
        viewsMetaInput.value = JSON.stringify(viewsMetaData)
        modal.modal('hide')
      }
      const hideModalEvent = e => {
        modal.modal('hide')
      }

      window.addEventListener('fileUploadWithPreview:imageClicked', e => {
        if (e.detail.uploadId === id) {
          modal.modal()
          item = e.detail.file
          if (preloaded && preloaded.length > e.detail.index && preloaded[e.detail.index]) {
              for (const [key, value] of Object.entries(preloaded[e.detail.index])) {
                item[key] = value
              }
          } else {
              preloaded[e.detail.index] = item
          }
          updateModalContent(e, modalSelector)
        }
      });

      modal.on('shown.bs.modal', e => {
        if (saveBtn) {
          saveBtn.on('click', saveModalEvent)
        }
      })

      modal.on('hide.bs.modal', e => {
        saveBtn.off('click', saveModalEvent)
      })
    }
  }
}

;(() => {
  document.onreadystatechange = () => {
     if (document.readyState === 'complete') {
       setupFileUpload()
       setupFileUpload('pdfsupload', '[name="pdfs_meta[]"]', '#pdfs-modal', '[name="pdfs_to_delete[]"]')
       setupFileUpload('avatarupload', '[name="avatar_meta[]"]', '#avatar-modal', '[name="avatars_to_delete[]"]', false)
     }
  }
})()

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="align-middle text-nowrap">{{ $tc("Name", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("E-Mail Address", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Role", 1) }}</th>
        <th class="align-middle text-nowrap" v-if="isSuperAdmin">{{ $t("Ip") }}</th>
        <th class="align-middle text-nowrap">{{ $t("Last Login") }}</th>
        <th class="align-middle text-nowrap" v-if="isSuperAdmin">{{ $tc("Id", 1) }}</th>
        <th class="align-middle text-nowrap text-right" colspan="2" v-if="canEdit || canDeactivate">{{ $tc("Action", 2) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items">
        <td class="align-middle">
          {{ item.username }}
        </td>
        <td class="align-middle">
          <small>{{ item.email }}</small>
        </td>
        <td class="align-middle">
          <small>{{ item.role || "—" }}</small>
        </td>
        <td v-if="isSuperAdmin">
          <small>{{ item.ip }}</small>
        </td>
        <td class="align-middle">
          <small>{{ getFormattedDate(item.last_logged_in) }}</small>
        </td>
        <td v-if="isSuperAdmin">
          <small>{{ item.uuid }}</small>
        </td>
        <td class="text-right align-middle" nowrap>
          <a v-if="item.can_edit" :href="getEditUrl(item.uuid)" class="btn btn-sm btn-outline-primary text-capitalize">
            {{ $t("Edit") }}
          </a>
          <button v-if="item.can_deactivate" type="button" @click="deleteItem(item.uuid)" class="btn btn-sm btn-outline-danger text-capitalize">
            {{ $t("Delete") }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    baseurl: String,
    items: Array,
    isSuperAdmin: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
  },
  methods: {
    deleteItem(uuid) {
      this.$emit("deleteItem", uuid);
    },
    getEditUrl(uuid) {
      return this.baseurl + "/" + uuid + "/edit";
    },
    getFormattedDate(date) {
      if (date) {
        const d = new Date(date);
        return ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      } else {
        return "—";
      }
    },
  },
};
</script>

import { render, staticRenderFns } from "./AuthorsTable.vue?vue&type=template&id=e627d200&scoped=true"
import script from "./AuthorsTable.vue?vue&type=script&lang=js"
export * from "./AuthorsTable.vue?vue&type=script&lang=js"
import style0 from "./AuthorsTable.vue?vue&type=style&index=0&id=e627d200&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e627d200",
  null
  
)

export default component.exports
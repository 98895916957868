<template>
    <span class="">
        <svg class="icon" focusable="false"><use xlink:href="#required"></use></svg>
        <div class="visually-hidden">{{ $t('Required')}}</div>
    </span>
</template>
<script>
    export default {
        props: {}
    }
</script>
import {
  isJSON
} from '../helpers.js'

document.addEventListener('DOMContentLoaded', (event) => {
  function activator (elem, status = false, hide = false) {
    const controls = elem.getAttribute('data-activator-controls')
    const arr = isJSON(controls) ? JSON.parse(controls) : [controls]

    let i = -1
    while (++i < arr.length) {
      const controllable = document.querySelector(arr[i])
      if (arr[i] && controllable) {
        controllable.disabled = !status
        if (hide && controllable.parentNode.classList.contains('form-group')) {
          controllable.parentNode.style.display = status ? 'block' : 'none'
        }
      }
    }
  }

  if (document.querySelector('.input-activator')) {
    const inputs = document.querySelectorAll('.input-activator')
    inputs.forEach(input => {
      const options = input.querySelectorAll('option')
      const hide = input.getAttribute('data-activator-hide')

      if (options.length) {
        // select
        let activatoroption = input.getAttribute('data-activator-option')

        activator(input, input.value === activatoroption, hide)
        input.addEventListener('change', e => {
          const activatoroption = input.getAttribute('data-activator-option')

          activator(input, input.value === activatoroption, hide)
        })
      } else {
        // checkbox
        activator(input, input.checked, hide)
        input.addEventListener('click', e => activator(e.target, input.checked, hide))
      }
    })
  }
})

/* global Vue */
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueLoading from 'vue-loading-template'

require('./editor')

window.Vue = require('vue')

Vue.use(VueI18n)
Vue.use(VueLoading)

const i18n = new VueI18n({
  locale: document.documentElement.lang || 'fr',
  messages: {
    'fr': require('../lang/fr.json'),
    'en': require('../lang/en.json')
  }
})

Vue.config.ignoredElements = [
  'tags',
  'tag',
  'x'
]
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component(
  'sanctum-api-tokens',
  require('./components/sanctum/ApiTokens.vue').default
)

Vue.component(
  'validation-errors',
  require('./components/ValidationErrors.vue').default
)

Vue.component(
  'actions',
  require('./components/tables/Actions.vue').default
)

Vue.component(
  'searchhelper',
  require('./components/search/SearchHelper.vue').default
)

Vue.component(
  'pagination',
  require('./components/search/Pagination.vue').default
)

Vue.component(
  'search',
  require('./components/search/Search.vue').default
)

Vue.component(
  'artiststable',
  require('./components/tables/ArtistsTable.vue').default
)
Vue.component(
  'artworkstable',
  require('./components/tables/ArtworksTable.vue').default
)
Vue.component(
  'callstable',
  require('./components/tables/CallsTable.vue').default
)
Vue.component(
  'networkstable',
  require('./components/tables/NetworksTable.vue').default
)
Vue.component(
  'newstable',
  require('./components/tables/NewsTable.vue').default
)
Vue.component(
  'userstable',
  require('./components/tables/UsersTable.vue').default
)
Vue.component(
  'translationtagstable',
  require('./components/tables/TranslationTagsTable.vue').default
)
Vue.component(
  'authorstable',
  require('./components/tables/AuthorsTable.vue').default
)
Vue.component(
  'inputtag',
  require('./components/inputs/InputTag.vue').default
)
Vue.component(
  'inputwithlabeltag',
  require('./components/inputs/InputWithLabelTag.vue').default
)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  el: '#app',
  i18n
})

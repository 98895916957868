<style scoped>
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="align-middle text-nowrap">{{ $tc("Avatar", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Name", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Active", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Date", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Is New (short)", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Is Updated (short)", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Network", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Author", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $t("Updated By") }}</th>
        <th class="align-middle text-nowrap" v-if="isSuperAdmin">{{ $tc("Id", 1) }}</th>
        <th class="align-middle text-nowrap text-right" colspan="2" v-if="canEdit || canDeactivate">{{ $tc("Action", 2) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items">
        <td class="align-middle">
          <img v-if="item.media !== undefined && item.media !== null && item.media.avatar && item.media.avatar.thumb" class="img-fluid rounded-circle" :src="item.media.avatar.thumb" width="35" alt="avatar" />
          <span v-else class="rounded-circle" style="background-color: #ddd; height: 35px; width: 35px; display: inline-block"></span>
        </td>
        <td class="align-middle">
          {{ item.fullname }}
        </td>
        <td class="align-middle">
          <span v-if="item.is_active" class="dot badge-success" alt="$tc('Active', 1)" title="$tc('Active', 1)"></span>
          <span v-else class="dot badge-danger" alt="$tc('Inactive', 1)" title="$tc('Inactive', 1)"></span>
        </td>
        <td class="align-middle">
          <small>
            {{ item.date ? getFormattedDate(item.date) : "—" }}
          </small>
        </td>
        <td class="align-middle">
          <input type="checkbox" @click="toggleIsNew(item)" :checked="item.is_new" />
        </td>
        <td class="align-middle">
          <input type="checkbox" @click="toggleIsUpdated(item)" :checked="item.is_updated" />
        </td>
        <td class="align-middle">
          <small>
            {{ item.network && item.network.fullname ? item.network.fullname : "—" }}
          </small>
        </td>
        <td class="align-middle">
          <small>{{ item.created_by && item.created_by.username ? item.created_by.username : "—" }}</small>
        </td>
        <td class="align-middle">
          <small>{{ item.last_updated_by && item.last_updated_by.username ? item.last_updated_by.username : "—" }}</small>
        </td>
        <td v-if="isSuperAdmin">
          {{ item.uuid }}
        </td>
        <td class="text-right align-middle" nowrap>
          <actions :canEdit="canEdit" :canDeactivate="canDeactivate" :item="item" :baseurl="baseurl" :hasPublishButton="true" :isPublished="!item.is_draft" itemType="artist" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    baseurl: String,
    items: Array,
    isSuperAdmin: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
  },
  methods: {
    toggleIsNew(item) {
      this.$emit("toggleIsNew", item);
    },
    toggleIsUpdated(item) {
      this.$emit("toggleIsUpdated", item);
    },
    getFormattedDate(date) {
      if (date) {
        const d = new Date(date);
        return ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
      } else {
        return "—";
      }
    },
  },
};
</script>

<template>
  <div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="align-middle text-nowrap">
            {{ $t("French") }}<svg class="icon" focusable="false"><use xlink:href="#required"></use></svg>
            <div class="visually-hidden">{{ $t("Required") }}</div>
          </th>
          <th class="align-middle text-nowrap">{{ $t("English") }}</th>
          <th class="align-middle text-nowrap">{{ $t("Type") }}</th>
          <th class="align-middle text-nowrap text-right" colspan="2" v-if="canEdit || canDeactivate">{{ $tc("Action", 2) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items">
          <td class="align-middle">
            <input type="text" :class="['form-control', !item.value.fr || !item.value.fr.length ? 'alert-danger' : '']" v-model="item.value.fr" required />
          </td>
          <td class="align-middle">
            <input type="text" class="form-control" v-model="item.value.en" />
          </td>
          <td class="align-middle">
            <small>
              {{ $t(item.type + " tag") }}
            </small>
          </td>
          <td class="text-right align-middle" nowrap>
            <actions :canEdit="canEdit" :canDeactivate="canDeactivate && item.type !== 'news'" :item="item" :baseurl="baseurl" :hasPublishButton="false" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group pb-3 editactions text-right">
      <hr />
      <button type="submit" @click="updateItems()" class="btn btn-primary">{{ $t("Update") }}</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    baseurl: String,
    items: Array,
    isSuperAdmin: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
  },
  methods: {
    deleteItem(id) {
      this.$emit("deleteItem", id);
    },
    updateItems() {
      this.$emit("updateItems");
    },
  },
};
</script>

/* global route */
import Tagify from '@yaireo/tagify'

if (document.querySelector('input.input-tags')) {
  const inputs = document.querySelectorAll('input.input-tags')
  inputs.forEach(input => {
    const whitelistDom = document.querySelector('.input-tags-whitelist') ? JSON.parse(document.querySelector('.input-tags-whitelist').value) : []

    if (whitelistDom) {
      ;(() => new Tagify(input, {
        whitelist: whitelistDom,
        dropdown: {
          maxItems: 8,
          fuzzySearch: true,
          position: 'text',
          closeOnSelect: true, // keep the dropdown open after selecting a suggestion
          highlightFirst: true
        },
        autoComplete: {
          enabled: true,
          rightKey: true
        }
      }))()
    }
  })
}

function getTagValue (type, data) {
  switch (type) {
    case 'artists':
      return data.fullname
    case 'networks':
      return data.fullname
    case 'artworks':
    case 'news':
      return data.title
    case 'tags':
    case 'venues':
    case 'cities':
      return data.name
    default:
      return ''
  }
}

document.addEventListener('DOMContentLoaded', (event) => {
  const baseUrl = route('search.form')
  const els = document.querySelectorAll('input.search-form')
  let controller

  els.forEach(el => {
    const type = el.getAttribute('data-type')
    const tagtype = el.getAttribute('data-tagtype')
    const tagify = new Tagify(el, {
      editTags: false,
      dropdown: {
        position: 'text',
        fuzzySearch: true,
        highlightFirst: true
      },
      autoComplete: {
        enabled: true,
        rightKey: true
      }
    })
    el.getAttribute('data-tags') && tagify.addTags(JSON.parse(el.getAttribute('data-tags')))
    tagify.settings.enforceWhitelist = !!el.hasAttribute('data-enforcewhitelist')

    const onInput = (e) => {
      controller && controller.abort()
      controller = new AbortController()
      var value = e.detail.value
      if (value.length < 2) {
        tagify.loading(false).dropdown.hide.call(tagify)
        return
      }
      tagify.settings.whitelist.length = 0 // reset the whitelist
      // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort

      // show loading animation and hide the suggestions dropdown
      tagify.loading(true).dropdown.hide.call(tagify)

      fetch(baseUrl + '?q=' + value + '&type=' + type + '&tags_type=' + tagtype, { signal: controller.signal })
        .then(result => result.json())
        .then(function (data) {
          if (data && data.data) {
            const whitelist = data.data.map(el => {
              return {
                'value': getTagValue(type, el),
                'uuid': el.uuid
              }
            })
            // update inwhitelist Array in-place
            tagify.settings.whitelist.splice(0, whitelist.length, ...whitelist)
            tagify.settings.enforceWhitelist = !!el.hasAttribute('data-enforcewhitelist')
            setTimeout(() => {
              tagify.loading(false).dropdown.show.call(tagify, value) // render the suggestions dropdown
            }, 50)
          }
        })
    }

    tagify.on('input', onInput)
  })
})

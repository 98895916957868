<style>

</style>
<template>
  <small id="searchHelpBlock" class="form-text text-muted">
    <span v-if="endpoint === 'artists'">{{$t('Search includes')}} {{$t('name')}}, {{$t('author')}}, {{$t('region')}}, {{$t('last updater')}}.<br/>{{showCommonKeywords()}} {{showArtistKeywords()}}</span>
    <span v-else-if="endpoint === 'artworks'">{{$t('Search includes')}} {{$t('title')}}, {{$t('artist')}}, {{$t('city')}}, {{$t('network')}}, {{$t('author')}}, {{$t('last updater')}}.<br/>{{showCommonKeywords()}} {{showArtworkKeywords()}}</span>
    <span v-else-if="endpoint === 'news'">
      {{$t('Search includes')}} {{$t('title')}}, {{$t('type')}}, {{$t('city')}}, {{$t('artist')}}, {{$t('author')}}, {{$t('last updater')}}.<br/>{{showCommonKeywords()}}{{showNewsKeywords().toLowerCase()}}.</span>
    <span v-else-if="endpoint === 'calls'">{{$t('Search includes')}} {{$t('title')}}, {{$t('type')}}, {{$t('author')}}, {{$t('last updater')}}.<br/>{{showCommonKeywords()}}</span>
    <span v-else-if="endpoint === 'networks'">{{$t('Search includes')}} {{$t('title')}} {{$t('and')}} {{$t('shortname')}}.</span>
    <span v-else-if="endpoint === 'users'">{{$t('Search includes')}} {{$t('name')}}, {{$t('email')}}, {{$t('role')}}.</span>
  </small>
</template>
<script>
  export default {
    props: {
      endpoint: {
        type: String,
        required: false
      }
    },
    methods: {
      showCommonKeywords() {
        return `${this.$tc('Filter keyword', 2)}: (${this.$t('not')}) ${this.$t('published')}`
      },
      showArtistKeywords() {
        return `, ${this.$t('new')}, ${this.$t('active')}, ${this.$t('updated')}.`
      },
      showArtworkKeywords() {
        return `, ${this.$t('public space')}.`
      },
      showNewsKeywords() {
        return `, ${this.$tc('Artist', 1)}, ${this.$t('network')}`
      }
    }
  }
</script>

<template>
  <div>
    <TransitionExpand>
      <FlashContent
        :key="rdm"
        :type="type"
        :message="msg"
        :errors="errs"
      />
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from "./TransitionExpand";
import FlashContent from "./FlashContent";

export default {
  components: {
    TransitionExpand,
    FlashContent,
  },
  props: {
    message: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "success",
    },
    time: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      timeout: undefined,
      msg: this.message,
      errs: this.errors,
      rdm: Math.random()
    };
  },
  watch: {
    message: function (newVal, oldVal) {
      this.msg = newVal;
      this.rdm = Math.random()
      if (this.time !== -1) {
        this.clearFlash()
      }
    },
    errors: function(newVal, oldVal) {
      this.errs = newVal
      this.rdm = Math.random()
      if (this.time !== -1) {
        this.clearFlash()
      }
    },
    time: function (newVal, oldVal) {
      if (newVal !== -1) {
        this.clearFlashImmediate();
      }
    },
  },
  methods: {
    clearFlash() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        function () {
          this.resetData()
        }.bind(this),
        this.time
      );
    },
    clearFlashImmediate() {
      clearTimeout(this.timeout);
      this.resetData()
    },
    resetData() {
      this.rdm = ""
      this.msg = ""
      this.errs = []
    },
    forceUpdate() {
      this.rdm = Math.random()
      this.msg = this.message
      this.errs = this.errors
    }
  },
};
</script>

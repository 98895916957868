<style scoped>

</style>

<template>
    <div class="form-group">
        <label :for="name">{{label}} <RequiredField v-if="required === true"/></label>
        <div v-for="(item, index) in val" class="mb-4 mb-xl-0">
            <!-- Title -->
            <input v-for="(hiddenItem, i) in hidden" 
                type="hidden" 
                :name="name + '[' + index + ']' + '[' + hiddenItem.name + ']'" 
                :value="hiddenItem.value"
                :key="index+'-'+i"
            />
            <div :key="index" class="d-flex flex-wrap mb-2">
                <!-- Label -->
                <div class="col-xl-3 px-0 mb-2 mb-xl-0 align-self-start pr-xl-1">
                    <input 
                    type="text"
                    class="form-control"
                    :name="name + '[' + index + ']' + '[label]'"
                    :key="index+'-label'" 
                    :required="index === 0 && required === true" 
                    :placeholder="$t('Label')"
                    v-model="val[index]['label']"
                    />
                </div>
                <!-- Input -->
                <div :class="'col-xl-6 px-0 mb-2 mb-xl-0 pl-xl-1 ' + getAdjustedClass()">
                    <template v-if="type === 'textarea'">
                    <textarea 
                        class="form-control"
                        :name="name + '[' + index + ']' + '[value]'"
                        :key="index+'-value'" 
                        :required="index === 0 && required === true" 
                        :placeholder="placeholder"
                        v-model="val[index]['value']"
                        />
                    </template>
                    <template v-else>
                        <input 
                        :type="type"
                        class="form-control"
                        :name="name + '[' + index + ']' + '[value]'"
                        :key="index+'-value'" 
                        :required="index === 0 && required === true" 
                        :placeholder="placeholder"
                        v-model="val[index]['value']"
                        />
                    </template>
                </div>
                
                <div :class="'px-0 pl-xl-2 d-flex ' + getExtraAdjustedClass()">
                    <!-- Switches -->
                    <template v-for="(item, i) in switches">
                        <div :key="i" class="form-group col align-self-center my-0 mx-auto p-0 mt-xl-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" 
                                :name="name + '[' + index + ']' + '[' + item.key + ']'" 
                                :id="name + '-' + item.key + '-' + index" 
                                v-model="val[index][item.key]"
                                >
                                <label class="custom-control-label" 
                                :for="name + '-' + item.key + '-' + index"  
                                :disabled="item.disabled ? 'disabled' : ''">{{item.label}}</label>
                            </div>
                        </div>
                    </template>

                    <!-- Delete -->
                    <button v-if="multiple === true && (index > 0)" 
                        :aria-label="$t('Delete')"
                        class="text-right btn btn-sm btn-outline-secondary align-self-center m-0 ml-auto"
                        style="height: 2.5em"
                        @click.prevent="handleRemoveClick(index)"><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></span>
                    </button>
                </div>
            </div>
            
        </div>
        
        <div>
            <!-- Add -->
            <button v-if="multiple === true" 
            class="btn btn-sm btn-outline-primary"
            @click.prevent="handleAddClick">{{ $t('Add') }}</button>
        </div>
    </div>
</template>

<script>
import RequiredField from './RequiredField.vue';
export default {
    props: {
        name: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: 'text'
        },
        label: {
            type: String,
            default: ""
        },
        lang: {
            type: String,
            default: 'fr'
        },
        value: {
            type: [Array, Object],
            default() {
                return [
                    {
                        'label': '',
                        'value': ''
                    }
                ]
            }
        },
        hidden: {
            type: Array,
            default() {
                return []
            }
        },
        switches: {
            type: [Array, Object],
            default() {
                return []
            }
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            val: Array.isArray(this.value) ? this.fixInputLanguage(this.value) : this.fixInputLanguage([this.value]),
        }
    },
    mounted() {
    },
    methods: {
        fixInputLanguage(input) {
            if (Array.isArray(input)) {
                if (!input.length) {
                    return [{label: '', value: ''}]
                }
                return input.map(item => {
                    if (typeof item.label === 'string' || item.label instanceof String) {
                        item.label = item.label
                    } else if (item.label && (this.lang in item.label)) {
                        item.label = item.label[this.lang]
                    }
                    if (item.is_published == undefined) {
                        if (Array.isArray(this.switches) && this.switches.length) {
                            item.is_published = this.switches[0].default
                        } else {
                            item.is_published = false
                        }
                    }
                    return item
                })
            }
        },
        handleAddClick() {
            this.val.push({
                'label': '', 
                'value': ''
            })
        },
        handleRemoveClick(index) {
            if (index > -1) {
                this.val.splice(index, 1)
            }
        },
        hasSwitch() {
            return (this.switches && this.switches.length) ? true : false
        },
        getAdjustedClass() {
            if (this.hasSwitch()) {
                return 'col-12 col-xl-6'
            }
            return 'col-xl-8'
        },
        getExtraAdjustedClass() {
            if (this.hasSwitch()) {
                return 'col-xl-3'
            }
            return 'col-xl-1'
        }
    },
    components: { RequiredField }
}
</script>
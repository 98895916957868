import EasyMDE from 'easymde'
import {marked} from 'marked'

function customPreviewRender(text) {
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
        var html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
    };
    return marked(text, { renderer: renderer });
}

document.addEventListener('DOMContentLoaded', (event) => {
  const markdownfields = document.querySelectorAll('.markdown-field')
  if (markdownfields) {
    markdownfields.forEach(field => {
      const maxHeight = field.getAttribute('maxHeight-data') ?? undefined
      let mde = new EasyMDE({
        hideIcons: [
          'image',
          'table',
          'fullscreen',
          'side-by-side'
        ],
        maxHeight: maxHeight,
        promptURLs: true,
        element: field,
        forceSync: true,
        styleSelectedText: false,
        spellChecker: false,
        previewRender: function(plaintext) {
          return customPreviewRender(plaintext)
        }
      })
    })
  }
})

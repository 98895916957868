<template>
  <div>
    <div
      v-bind:class="[
        'alert',
        type === 'success' ? 'alert-success' : 'alert-danger',
      ]"
      v-if="message || (errors && errors.length)"
    >
      <span v-if="type === 'success'">
        {{ message }}
      </span>
      <div v-if="type === 'error'">
        <p class="mb-0"><strong>Erreur</strong></p>
        <br />
        <ul>
          <li v-for="error in errors">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: [String, Array],
      default: "",
    },
    errors: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "success",
    },
    disappearing: {
      type: Boolean,
      default: true,
    },
    time: {
      type: Number,
      default: 1000,
    }
  }
};
</script>

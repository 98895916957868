export const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export const isJSON = str => {
  try {
    return (JSON.parse(str) && !!str)
  } catch (e) {
    return false
  }
}

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="align-middle text-nowrap">{{ $tc("Name", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Shortname", 1) }}</th>
        <th class="align-middle text-nowrap">{{ $tc("Artist", 2) }}</th>
        <th class="align-middle text-nowrap" v-if="isSuperAdmin">{{ $tc("Id", 1) }}</th>
        <th class="align-middle text-nowrap text-right" colspan="2" v-if="canEdit || canDeactivate">{{ $tc("Action", 2) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" v-if="isSuperAdmin || item.shortname != 'RDDA'">
        <td class="align-middle">
          {{ item.fullname }}
        </td>
        <td class="align-middle">
          {{ item.shortname }}
        </td>
        <td class="align-middle">
          <template v-if="item.artists">
            {{ item.artists.length }}
          </template>
          <template v-else> 0 </template>
        </td>
        <td v-if="isSuperAdmin">
          {{ item.uuid }}
        </td>
        <td class="text-right align-middle" nowrap>
          <a v-if="canEdit" :href="getEditUrl(item.uuid)" class="btn btn-sm btn-outline-primary text-capitalize">
            {{ $t("Edit") }}
          </a>
          <button v-if="canDeactivate && item.shortname !== 'RDDA'" type="button" @click="deleteItem(item.uuid)" class="btn btn-sm btn-outline-danger text-capitalize">
            {{ $t("Delete") }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    baseurl: String,
    items: Array,
    isSuperAdmin: Boolean,
    canEdit: Boolean,
    canDeactivate: Boolean,
  },
  methods: {
    deleteItem(uuid) {
      this.$emit("deleteItem", uuid);
    },
    getEditUrl(uuid) {
      return this.baseurl + "/" + uuid + "/edit";
    },
  },
};
</script>
